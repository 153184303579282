import React from 'react'
import {Link} from 'react-router-dom'
export default function NotFound() {
  return (
    <div style={{"marginTop":"200px", "textAlign":"center",'color':"white","fontSize":"2rem",
    "fontFamily":"corbel"}}>
        You are Unauthorized to access this web page!!!!<br/>
        <Link to="/login">Go to Login Page</Link>
    </div>
    
  )
}