import axios from 'axios'
import React,{useRef,useState} from 'react'
import {Form,Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
export default function TaskAdminCreate() {
    const taskTitleRef = useRef()
    const taskContentRef = useRef()
    const taskLinkRef = useRef()
    const taskPointsRef = useRef()
    const lastDateRef = useRef()
    const [error, setError] = useState('');
    const [loading,setLoading] = useState(false);
    const [success,setSuccess] = useState('');
    async function handleSubmit(e){
        e.preventDefault();
        // e.reset();
        setSuccess('');
        setLoading(true);
        setError('');
        axios.post('https://caportal25.onrender.com/api/create-task',{
            title : taskTitleRef.current.value,
            content: taskContentRef.current.value,
            link: taskLinkRef.current.value,
            lastDate : lastDateRef.current.value,
            points : taskPointsRef.current.value
        }).then((response)=>{
            setLoading(false)
            setSuccess("Task Created Successfully")
            console.log(response)
        }).catch((e)=>{
            setLoading(false)
            setError('Error creating task')
            console.log(e)
        })
        
    }
  return (
        <div className='admincolor'>
        <h2 className="text-center mb-4">
            Add Task
        </h2>
        {error && <Alert variant ="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {/* <Form onSubmit={handleSubmit}>
            <Form.Group id="title">
                <Form.Label>Task Title</Form.Label>
                <Form.Control type="text" ref={taskTitleRef} required ></Form.Control>
            </Form.Group>
            <Form.Group id="content">
                <Form.Label>Task Content</Form.Label>
                <Form.Control 
                    type="text" 
                    ref={taskContentRef}
                    required
                ></Form.Control>
            </Form.Group>
            <Form.Group id="points">
                <Form.Label>Task Points</Form.Label>
                <Form.Control type="number" ref={taskPointsRef} required></Form.Control>
            </Form.Group>
            <Form.Group id="lastDate">
                <Form.Label>Last Date</Form.Label>
                <Form.Control type="date" ref={lastDateRef} required></Form.Control>
            </Form.Group>
            <Button disabled={loading} type="submit" className="w-100 mt-3">Add Task</Button>
            <Button type="reset" className="w-100 mt-3">Reset</Button>
            <div className="w-100 text-center mt-2">
                <Link to="/admin">Go back to Admin page</Link>
            </div>
        </Form> */}
        <Form onSubmit={handleSubmit}>
                
                    <Form.Label>Task Title</Form.Label>
                    <TextField sx={{ input: { color: 'white',borderColor:'white', border: '1px solid #ced4da', } }}  margin='normal' size='small' fullWidth type="name" inputRef={taskTitleRef} /> 
           
                
                    <Form.Label>Task Content</Form.Label>
                    <br/><br/>
                    <TextareaAutosize sx={{ input: { color: 'white',borderColor:'white', border: '1px solid #ced4da', } }} margin='normal' size='small' fullWidth type="name" ref={taskContentRef} required />
               
                    <br/><br/>
                    <Form.Label>Task Link</Form.Label>
                    <TextField sx={{ input: { color: 'white',borderColor:'white', border: '1px solid #ced4da', } }} margin='normal' size='small' fullWidth type="tek" inputRef={taskLinkRef} />
                    <br/><br/>
                    
                    <Form.Label>Task Points</Form.Label>
                    <TextField sx={{ input: { color: 'white',borderColor:'white', border: '1px solid #ced4da', } }} margin='normal' size='small' fullWidth type="tek" inputRef={taskPointsRef} required />
                
                    <Form.Group id="lastDate">
                <Form.Label>Last Date</Form.Label><br/>
                <Form.Control type="date" ref={lastDateRef} required></Form.Control>
            </Form.Group>
<div className='adminCenter'><Stack direction="row" spacing={2}>
                
                    <Button variant='contained' disabled={loading} type="submit" className="w-100 mt-3">Add Task</Button>
            <Button variant='contained' type="reset" className="w-100 mt-3">Reset</Button></Stack></div>
            {/* <div className="w-100 text-center mt-2">
                <Button variant='contained'><Link style={{textDecoration:'none'}} to="/admin">Go back to Admin page</Link></Button>
            </div> */}

                
            </Form>
        </div>
        
  )
}
