import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles.css";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'right',
    height: '8vh',
    color: theme.palette.text.secondary,
  }));
function Adminlayout() {
    return (
        <div className='show-container-dark' >
       <Box>
      <Grid container spacing={1}>
      <Grid item>
         <div><Navbar /></div><br/><br/>
        </Grid>
        <Grid item xs={12}>
          <div><Outlet /></div>
        </Grid>
      </Grid>
    </Box>     
    </div>
    );
}

export default Adminlayout;