import React,{useState,useEffect}from 'react'
import {useAuth} from '../../context/AuthContext'
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import '../styles.css';
import { TextField,useMediaQuery, useTheme, Button, Box, CssBaseline, Drawer, AppBar,IconButton,Toolbar, Stack, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from './CA_light.png';
import logo2 from './Logo_white.png';
import { styled } from "@mui/material/styles";
import MenuItem from '@mui/material/MenuItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { FaHome, FaUser, FaGift, FaChartBar, FaEnvelope, FaPhone, FaTasks, FaCheck, FaCheckCircle, FaCheckDouble, FaCheckSquare } from "react-icons/fa";
import './Sidebar.css';
import axios from 'axios';
import zIndex from '@mui/material/styles/zIndex';

const drawerWidth = 240;
const useStyles = makeStyles({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#1E2738",
      width: '200px'
    }
  },
  flexGrow: {
    flex: '1',
  },
  button: {
    color: '#fff',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#3c52b2',
  },
}
});
function Sidenavbar(props) {
  
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [error,setError] = useState('');
  const { loginWithGoogle, logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [checkEmail, setcheckEmail] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // Added state for the modal
  const [college, setCollege] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [loading, setLoading] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false); // Add a state to track confirmation

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [hovered, setHovered] = useState(null);

  const menuItems = [
    { id: 1, icon: <FaHome color='white' />, label: "Home", link: "/" },
    { id: 2, icon: <FaTasks color='white'/> , label: "Dashboard", link: "/dashboard" },
    { id: 3, icon: <FaCheckSquare color='white'/>, label: "Submitted Tasks", link: "/dashboard/task-pending"},
    { id: 4, icon: <FaChartBar color='white'/>, label: "Leaderboard", link: "/dashboard/leaderboard" },
    { id: 5, icon: <FaGift color='white'/>, label: "Incentives", link: "/dashboard/incentives" },
    { id: 6, icon: <FaUser color='white'/>, label: "Profile", link: "/dashboard/profile"},
    { id: 7, icon: <FaPhone color='white'/>, label: "Contact Us", link: "/dashboard/contact-us" },

  ];

  const drawer = (
    <div className="sidebar">
    {menuItems.map((item) => (
       <Link to = {item.link} style={{textDecoration: 'none'}} onClick={handleDrawerToggle}>
      <div
        key={item.id}
        className="sidebar-item"
        onMouseEnter={() => setHovered(item.id)}
        onMouseLeave={() => setHovered(null)}
      >
        <div className="icon">{item.icon}</div>
        {isLargeScreen && hovered === item.id && <div className="label">{item.label}</div>}
      </div>
      </Link>
    ))}      
      </div>
  );
 
 

  const handleGoogleLogin = async (e) => {
    e.preventDefault();

    try {
        setError('');
        setLoading(true);
        const result = await loginWithGoogle();
        // const checkEmail = result.user.email;
        setcheckEmail(result.user.email);
        console.log(checkEmail)
        const checkMail = await axios.post('https://caportal25.onrender.com/api/check-valid-email', { email: result.user.email });

        if (checkMail.data === 'Not Valid') {
            logout();
            setShowConfirmationModal(true);
        } else {
            
            navigate(`/dashboard`);
        }

        setLoading(false);
    } catch {
        setError('Failed to Sign In.');
        setLoading(false);
    }
};

const handleConfirmNewUser = async () => {
  setIsConfirming(true); // Set to true when confirming
  
  try {
      if (!college || !phoneNo) {
          setError('Please fill in all required fields.');
          // setIsConfirming(false); // Set to true when confirming
          
          return;
      }
      
      const phoneNoPattern = /^[0-9]{10}$/; // Assuming a 10-digit phone number format
      if (!phoneNo.match(phoneNoPattern)) {
          setError('Invalid phone number format. Please enter a 10-digit phone number.');
          // setIsConfirming(false); // Set to true when confirming
          return;
      }
      // const result = await loginWithGoogle();
      // const checkEmail = result.user.email;
      console.log(checkEmail)
      setShowConfirmationModal(false);
      await axios.post('https://caportal25.onrender.com/api/login-signup-newuser', {
          email: checkEmail,
          college: college,
          phoneNo: phoneNo,
      });
      // await loginWithGoogle();
      await loginWithGoogle();
  } catch (error) {
      console.error('Error:', error);
      // Handle the error gracefully (show an error message, etc.)
  }
  finally {
      navigate('/dashboard/profile');
      setIsConfirming(false); // Reset to false when done processing
  }
};

const handleCancelConfirmation = () => {
  setShowConfirmationModal(false);
  logout();
};

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
    async function handleLogout(){
        try{
            setError('');
            await logout();
            navigate('/');
        } catch{
            setError('Failed to logout')
        }
    }
  const classes = useStyles();
  return (
    <div>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav"  color="transparent" sx={{backgroundColor:'transparent',padding:0, borderColor:'transparent'}}>
        <Toolbar>
        { currentUser && (<IconButton
            color="inherit"
           
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton> )}

          
          
          
          {currentUser ? (<>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={logo} alt="logo" className='logo'  />
              </Typography>
              <Button variant='contained' onClick={handleLogout} >Log Out</Button>
              </>
            ) : (
              <><Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={logo2} alt="logo" className='logo2' width={"200px"}  />
              </Typography>
              
              <Button variant='contained'  onClick={handleGoogleLogin} spacing={3}>Sign In</Button>
                {/* Custom Modal for Confirmation */}
            <Dialog open={showConfirmationModal} onClose={handleCancelConfirmation}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                {/* <Alert variant={danger}> error </Alert> */}
                <DialogContentText>
                    No user with this email found. Do you want to sign up with a new account? Fill in the following details compulsorily to proceed.
                </DialogContentText>
                <TextField
                    label="College"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={college}
                    onChange={(e) => setCollege(e.target.value)}
                    error={!!error && !college} // Show error if error exists and college is empty
                    helperText={(error && !college) ? error : ''}
                />
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    error={!!error && !college} // Show error if error exists and college is empty
                    helperText={(error && !college) ? error : ''}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelConfirmation} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmNewUser} disabled={isConfirming} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        </>
            )}
          
        </Toolbar>
      </AppBar>

       {/* Side Navigation Bar: Only visible when user is logged in */}
      { currentUser && (
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
        PaperProps={{
          sx: { backgroundColor: "transparent",
            backdropFilter: "blur(6px)",
           }
            }}
         
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "50vw" },
          }}
        >
          {drawer}
        </Drawer>
        {isLargeScreen && (
        <Box
        PaperProps={{
          sx: { backgroundColor: "transparent" }
                }}
          
          variant="permanent"
          open
          sx={{
            '& .MuiDrawer-paper': { 
              border: "none",
             
            backdropFilter: "blur(120px)",
              boxSizing: 'border-box', width: drawerWidth },
          }}
          >
          {drawer}
        </Box> )}
      </Box> )}
    </Box>
  </div>
  );
}
Sidenavbar.propTypes = {
  window: PropTypes.func,
};

export default Sidenavbar;