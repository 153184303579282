// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD042q2jsdoe4jOlqkkvglpy8VWwc1XTn4",
  authDomain: "ca-portal-93586.firebaseapp.com",
  projectId: "ca-portal-93586",
  storageBucket: "ca-portal-93586.appspot.com",
  messagingSenderId: "954012489264",
  appId: "1:954012489264:web:38673ecdb2017dcc26bff5",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDOfKbE9DILBnlvYZkFPWFpqMm5V5yP0bA",
//   authDomain: "auth-development-d2c09.firebaseapp.com",
//   projectId: "auth-development-d2c09",
//   storageBucket: "auth-development-d2c09.appspot.com",
//   messagingSenderId: "1068012725931",
//   appId: "1:1068012725931:web:3b75aacf7f717d9e1539ee"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export function FirebaseApp(){
    return initializeApp(firebaseConfig);
}
const storage = getStorage(app);
export {storage};
