import React from 'react'
import axios from 'axios'
import { Container, Card} from 'react-bootstrap'
import Alluser from './AllUsersNode'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import { ExportSheet } from 'react-xlsx-sheet'
import {CSVLink} from 'react-csv';
import GetAppIcon from '@mui/icons-material/GetApp';

let alluser;
axios.get('https://caportal25.onrender.com/api/get-all-users').then((response)=>{
    alluser = response.data
    // console.log(response.data)
}).catch((e)=>console.log(e))

function taskEntry(user){
    return (
       <Alluser
            key = {user._id}
            uid = {user.uid}
            name ={user.name}
            college = {user.college}
            email = {user.email}
            phone = {user.phone}
            techid = {user.techid}
            points = {user.points}
       />
    )
}
export default function ShowTask() {
  return (

    <div className='resp-user'>
    <div className='allUsers'>
  
        <Typography variant='h2' className='h2leader'>All Users</Typography>
       <buton className='button-21'> <CSVLink data={alluser}><GetAppIcon></GetAppIcon> EXPORT</CSVLink></buton>
        <Table>
        <TableHead>
        <TableRow>
          <TableCell sx={{color:'#fff'}}>TechId</TableCell>
          <TableCell sx={{color:'#fff'}}>Name</TableCell>
          <TableCell sx={{color:'#fff'}}>College</TableCell>
          <TableCell sx={{color:'#fff'}}>Phone</TableCell>
          <TableCell sx={{color:'#fff'}}>Email</TableCell>
          <TableCell sx={{color:'#fff'}}>Points</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
    {/* {
        dataArray.map((user,index)=>LeaderBoardEntry(user,index))
    } */}
     {  
      
        alluser.map((user,index)=>(
          
          <TableRow>
          <TableCell sx={{color:'#fff'}}>{user.techid}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.name}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.college}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.phone}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.email}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.points}</TableCell>
          </TableRow>
        ))
    }
    <br/>
    </TableBody>
    </Table>
    
    </div>
    </div>
  )
}
// https://data.mongodb-api.com/app/data-zabod/endpoint/data/v1
// API Key: CQvgqYOXpk4dH9iEEHE7AezbX0WAOeQiQqn0c4siEeIguZ7eLOlW403J6tPfILDQ