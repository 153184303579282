import axios from 'axios'
import React,{useState,useEffect}from 'react'
import TaskCreateNode from './TaskCreateNode'
import {useAuth} from '../context/AuthContext'
import useFetch from './userInfo'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import './styles.css'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors'
// let dataArray = []
// axios.get('https://caportal24.onrender.com/api/fetch-pending-task').then((response)=>{
//     dataArray= response.data
//     // console.log(dataArray[0].title)
// }).catch((e)=>console.log(e))

// const task = dataArray[0]
function taskEntry(task){
    return(
        <TaskCreateNode 
            key = {task._id}
            id = {task._id}
            title ={task.title}
            points = {task.points}
            content = {task.content}
            link = {task.link}
            offeredDate = {task.OfferedDate}
            lastDate = {task.lastDate}
        />
    )
}
export default function TaskList() {
    const {currentUser} = useAuth();
    const [dataArray, setDataArray] = useState([]);
    // console.log(currentUser.email);
    axios.get(`https://caportal25.onrender.com/api/fetch-pending-task?email=${encodeURIComponent(currentUser.email)}`)
  .then((response) => {
    setDataArray(response.data);
    // console.log(response.data);
  })
  .catch((error) => {
    console.error(error);
  });

    // console.log(currentUser.email)
    // const [taskToShow,setTasktoShow] = useState([])
    // const {currentUser} = useAuth()
    // const userInfo = useFetch(currentUser.uid)
    // useEffect(()=>{
    //     if(userInfo!==undefined){
    //         // console.log(userInfo[0].isPending)
    //         // console.log(dataArray)
    //         let tempData =[]
    //         dataArray.forEach((element)=>{
    //             if(userInfo[0].isPending.find((taskid)=>taskid===element._id)===undefined
    //             && userInfo[0].isCompleted.find((taskid)=>taskid===element._id)===undefined)
    //                 tempData.push(element) 
    //             setTasktoShow(tempData)
    //         })
    //     }
    // },[userInfo]) 
  return (
    
    <Box style={{  background: 'transparent', color:'#fff', borderRadius:'20px', marginTop: '60px'}}>
    
    
        <Box sx={{marginTop: "15vh"}}>
        <Typography  variant="h2" className='h2leader' align='center' color={"white"}  fontFamily={"fantasy"} >
        Pending Tasks
      </Typography>
      </Box>
        <Grid container  >
                {
                dataArray.map((element=>{
                    return taskEntry(element)
                }))
                }
             
         
        </Grid>
        
       
        
        
    </Box>
  )
}
