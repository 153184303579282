import axios from 'axios';
import React,{useState,useEffect}from 'react'
import {Alert} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../context/AuthContext.js'
import Button from '@mui/material/Button';
import './styles.css';
import Card from '@mui/material/Card';
import { Box, CardContent, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
export default function Dashboard() {
    const [error,setError] = useState('');
    const {currentUser,logout} = useAuth();
    const photoURL = currentUser.photoURL;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const [currentUserInfo,setCurrentUserInfo] = useState({
        name : "",
        email : email,
        college: "",
        // uid : currentUser.uid,
        phone : "",
        techid:"",
        linkedin: currentUser.linkedin,
        facebook: currentUser.facebook,
        points:0
    })

        axios.post('https://caportal25.onrender.com/api/getuserdata',{
        // uid : currentUser.uid,
        email : currentUser.email,
        })
        .then((response)=>{
            setCurrentUserInfo({
                name : response.data.name,
                college: response.data.college,
                collegeCity: response.data.collegeCity,
                collegeState: response.data.collegeState,
                email : response.data.email,
                uid : response.data.uid,
                phone : response.data.phone,
                techid:response.data.techid,
                points:response.data.points,
                linkedin: response.data.linkedin,
                facebook: response.data.facebook,
                instagram: response.data.instagram,
                program: response.data.program,
                joiningYear: response.data.joiningYear,
            })
        })
        .catch((e)=>console.log(e))
    const navigate = useNavigate();
    async function handleLogout(){
        try{
            setError('');
            await logout();
            navigate('/login');
        } catch{
            setError('Failed to logout')
        }
    }
  return (
   
    <div className = 'profile-card'>
        
            <Box sx={{paddingTop: "30px", marginTop: '40px', marginBottom: '10px'}}>
            <Typography variant='h2' align='center' className='h2incentives' fontFamily={"fantasy"}>Profile</Typography>
            </Box>
            <Card style={{  background: 'radial-gradient(circle at 10% 20%, rgb(114, 104, 136) 0%, rgb(27, 16, 43) 100.2%)', color:'#fff', borderRadius:'20px'}}>
            <CardContent className='cardProfile'>
            <Grid container>
            <Grid classname = 'resp-profile' sx={{marginLeft:'50px', marginRight:'10px', paddingTop: '10px'}} >
            
                <Avatar alt="name" className='avatar' src={photoURL} sx={{width:150, height:150}} />
                </Grid><Divider orientation="vertical" flexItem />
                <Grid xs style={{padding:50, verticalAlign:'center'}}>
                {error && <Alert variant ="danger">{error}</Alert>}
                <h2 className='h2name'>{currentUserInfo.name}</h2>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs style={{padding:'10px 50px 30px 50px'}}>
                {error && <Alert variant ="danger">{error}</Alert>}
                <div className='row'>
                <div className='column'>
                <p className='p-profile'>Name&nbsp;&nbsp;&nbsp;: {currentUserInfo.name} </p> 
                <p className='p-profile'>Email&nbsp;&nbsp;&nbsp;: {currentUserInfo.email} </p> 
                <p className='p-profile'>Phone&nbsp;&nbsp;&nbsp;: {currentUserInfo.phone} </p>  
                <p className='p-profile'>College&nbsp;: {currentUserInfo.college} </p>  
                <p className='p-profile'>College City&nbsp;: {currentUserInfo.collegeCity} </p>  
                <p className='p-profile'>College State&nbsp;: {currentUserInfo.collegeState} </p>  
                <p className='p-profile'>Program&nbsp;: {currentUserInfo.program} </p>  
                <p className='p-profile'>Year of Joining&nbsp;: {currentUserInfo.joiningYear} </p>  
                <p className='p-profile'>Techkriti Coins&nbsp;&nbsp;: {currentUserInfo.points} </p>
                <p className='p-profile'>C.A. ID&nbsp;&nbsp;: {currentUserInfo.techid} </p>
                <p className='p-profile'>Referral ID&nbsp;&nbsp;: {currentUserInfo.referralId} </p>
                <p className='p-profile'>Linkedin&nbsp;&nbsp;: {currentUserInfo.linkedin} </p>
                <p className='p-profile'>Facebook&nbsp;&nbsp;: {currentUserInfo.facebook} </p>
                <p className='p-profile'>Instagram&nbsp;&nbsp;: {currentUserInfo.instagram} </p>
                </div>
                {/* <div className='column'>
                <p >{currentUserInfo.email} </p> 
                <p>{currentUserInfo.phone} </p>
                <p>{currentUserInfo.college} </p>  
                <p>{currentUserInfo.points} </p>
                <p>{currentUserInfo.techid} </p>
                </div> */}
                </div>
                </Grid>
            </Grid>
            <div >
            
            <Button variant='contained' sx={{backgroundColor:'#008b8b', marginLeft: 30}}><Link style={{textDecoration:'none', color:'#fff'}} to="/dashboard/update-profile" className="">Update Profile</Link></Button>
            </div>
            
            </CardContent>
        </Card> <Grid xs></Grid>
          
        </div>
    
  )
}

