import axios from 'axios'
import React, { useState, useEffect } from 'react'
import LeaderBoardNode from './/LeaderboardNode'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Grid, Typography, Card } from '@mui/material';
import MaterialTable from 'material-table';
import './styles.css'
import {useAuth} from '../context/AuthContext'
import { propsToClassKey } from '@mui/styles';
import { Stack } from 'react-bootstrap';
import { forEach } from 'lodash';

// function LeaderBoardEntry(user,index){
//     return <LeaderBoardNode 
//         key = {index}
//         name ={user.name}
//         points = {user.points}
//         rank = {user.rank}
//     />
// }
 
// dataArray2.slice(0,10);

export default function Leaderboard() {
  
  const { currentUser } = useAuth();
  const [dataArray, setDataArray] = useState([]);
  const [useremail, setUseremail] = useState('');

  useEffect(() => {
    const getData = async() => {
      axios.get('https://caportal25.onrender.com/api/sorted-user-info').then((response)=>{
        setDataArray(response.data);
      }).catch((err)=>console.log(err))
    }
    setUseremail(currentUser.email);
    getData();
    
  }, []);
  // const {currentUser} = useAuth()
  // function checkRank(user) {
  //   const first = user.uid
  //   const sec = currentUser.uid
  //   return (first===sec)
  //   // console.log(user.uid, currentUser.uid)
  //   // return 1
    
  // }

// var userRank = dataArray.find(checkRank)
// var userRankFinal;
// if(userRank===null)
// userRankFinal=undefined
// else
// userRankFinal = userRank.rank
// console.log(userRank)
let limitCount=0
// for(let i=0;i<dataArray.length;i++){
//   if(dataArray[i].email == useremail){
//     setUserRank(dataArray[i].rank);
//     break;
//   }
// }
let userRank = 0;
for(let user of dataArray) {
  if(user.email === useremail) {
    userRank = user.rank;
  }
}
  return (
    <Card style={{  background: 'transparent', color:'#fff', borderRadius:'20px', marginTop: '10px'}}>
    
  <div item className='resp-leader'>
    <Stack direction="row" spacing={2}>
        <Typography variant='h2' align='center' className='h2leader' fontFamily={"fantasy"}>LeaderBoard</Typography>
        
    </Stack>
    <br/>

    <div className='leaderboard'>
    <Stack direction="row" >
    <Typography variant='h5'>Your Rank :{userRank}</Typography>
    </Stack>
    <br/>
        <Table>
        <TableHead>
        <TableRow>
        <TableCell sx={{color:'#fff', fontWeight:"bold"}}>Rank</TableCell>
          <TableCell sx={{color:'#fff', fontWeight:"bold"}}>Name</TableCell>
          <TableCell sx={{color:'#fff', fontWeight:"bold"}}>Techkriti Coins</TableCell>
          <TableCell sx={{color:'#fff', fontWeight:"bold"}}>Referrals</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
    {/* {
        dataArray.map((user,index)=>LeaderBoardEntry(user,index))
    } */}
     {  
      
        dataArray.slice(0,12).map((user,index)=>(
          
          <TableRow>
          <TableCell sx={{color:'#fff'}}>{user.rank}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.name}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.points}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.referralCount}</TableCell>
          </TableRow>
        ))
    }
    <br/>
    </TableBody>
    </Table>
    </div>
    </div>
  
    </Card>
  )
}