import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidenavbar from './Sidenavbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../styles.css';
import Bgvideo from './bgvideo';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'right',
  height: '10vh',
  backgroundColor:'transparent',
  color: theme.palette.text.secondary,
}));

function Userlayout() {
  
    return (
      // <Container
      //   fixed
      //   sx={{ backgroundColor: "#16003B", maxWidth: "100% !important", margin: 0 }}
      // ><CssBaseline />
      
        
      <div className='new'>
       
        <Bgvideo/>
      <Grid container spacing={2}>
      <Grid item xs={12} sx={{boxShadow:0, border:0}}>
          
        </Grid>
        <Grid item xs = {'auto'} sm = {'auto'} sx={{boxShadow:0, border:0}}>
          <div><Sidenavbar /></div>
        </Grid>
        <Grid item xs={12} sm = {9}>
          <div className='resp'><Outlet /></div>
        </Grid>
      </Grid>
        
    </div>
     
    );
}

export default Userlayout;