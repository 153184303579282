import React from 'react';
import '../styles.css';
import bgvideolanding from './vecteezy_gently-flowing-shiny-purple-wavy-lines-full-hd-and-looping_34090936.mp4';

export default function Bgvideolanding() {
    return (
        <div>
        <video autoPlay loop muted id ="video_land" preload="auto">
        <source src={bgvideolanding} type="video/mp4"/>
        </video>

        </div>
    )
}

