import React, { useState } from "react";
import { Card, Alert } from "react-bootstrap";
import axios from 'axios';

function YourComponent(props) {

    const [title, setTitle] = useState(props.title);
    const [content, setContent] = useState(props.content); 
    const [link, setLink] = useState(props.link);
    const [points, setPoints] = useState(props.points); 
    const [lastDate, setLastDate] = useState(props.lastDate);
    const [hidden, setHidden] = useState(true);

    const updateTask = () => {
        axios.post('https://caportal25.onrender.com/api/update-task',{id:props.id, deadline: lastDate, title: title, content: content,link: link, points: points}).then((response)=>{ 
            setHidden(false);
            setTimeout(() => {
                setHidden(true);
            }, 3000);
        }).catch((e)=>console.log(e))
    };

    return (
        <>
        <Card>
            <Card.Body>
                <Alert variant="success" hidden={hidden}>Task Updated sucessfully</Alert>  
                <strong>Task ID</strong>: {props.id}<br />
                <strong>Task Name</strong>: 
                <input 
                    type="text" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                /><br />
                <strong>Task Content</strong>:
                <input
                    type="text" 
                    value={content} 
                    onChange={(e) => setContent(e.target.value)} 
                /><br />
                 <strong>Task Link</strong>:
                <input
                    type="text" 
                    value={link} 
                    onChange={(e) => setLink(e.target.value)} 
                /><br />
                <strong>Release Date</strong>: {props.offeredDate}<br />
                <strong>Deadline</strong>: 
                <input 
                    type="date" 
                    value={lastDate} 
                    onChange={(e) => setLastDate(e.target.value)} 
                /><br />
                <strong>Task Points</strong>:
                <input 
                    type="number" 
                    value={points} 
                    onChange={(e) => setPoints(e.target.value)} 
                /><br />
                <button onClick={updateTask}>Update Task</button>
            </Card.Body>
        </Card>
        </>
    );
}

export default YourComponent;
