import React,{useState,useEffect}from 'react'
import {Alert} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button';
import "./styles2.css";
import Card from '@mui/material/Card';
import { Box, CardContent, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Incentives() {
    
    const rewardsData1 = [
        {
          icon:"fas fa-graduation-cap" ,
          iconHeight: '50px',
          description: 'Guaranteed paid and unpaid internships to top CAs.',
        },
        {
          icon: "fas fa-trophy",
          iconHeight: '50px',
          description: 'Four-year mentorship program tailored to benefit our CAs.',
        },
        {
          icon: "fas fa-gift",
          iconHeight: '50px',
          description: 'Premium subscriptions and courses to boost your knowledge and career.',
        },
       
      ];

      const rewardsData2 = [
        {
          icon: "fas fa-chalkboard-teacher",
          iconHeight: '70px',
          description: 'Training programs, interaction sessions, and webinars from industry experts for free.',
        },
        {
          icon: "	fas fa-bookmarkfas fa-gift",
          iconHeight: '70px',
          description: 'Certificate of appreciation and an internship letter of recommendation to the top performing CAs.',
        },
        {
          icon: "fas fa-building",
          iconHeight: '70px',
          description: 'Top CAs will get a chance to visit IIT Kanpur and attend a premium workshop for free.',
        },
        
      ];

      const rewardsData3 = [
        {
          icon: "fas fa-business-time",
          iconHeight: '70px',
          description: 'Supervised courses from industry experts on entrepreneurial domain.',
        },
        {
          icon: "fas fa-medal",
          iconHeight: '50px',
          description: 'Stand a chance to collaborate with Techkriti to organize workshops and competitions at your institution.',
        },
      ];
    
  return (

   
    <div >
        <Card style={{  background: 'transparent', color:'#fff', borderRadius:'20px', marginTop: '60px'}}>
            <Box >
            <Typography variant='h2' align='center' className='h2incentives' fontFamily={"fantasy"}>Incentives</Typography>
            </Box>
            <br/><br/>
            
      <Grid container spacing={1} justifyContent="center" >
                <Grid item className='ContactUs-rows'>
                {rewardsData1.map((reward, index) => (
                  <Card key={index} className="card" style={{ color: "white", padding: "20px", textAlign: "center" }} >
                    <div className="pad" style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white' }}>
                       <i className={reward.icon} style={{ fontSize: "50px", color: "white" }}></i></div>
                      <div style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <div >
                        <p>
                         {reward.description}
                         </p>
                      </div>
                    </div>
                  </Card> ))}
                  </Grid>

                  <Grid item className='ContactUs-rows'>
                {rewardsData2.map((reward, index) => (
                  <Card key={index} className="card" style={{ color: "white", padding: "20px", textAlign: "center" }} >
                    <div className="pad" style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white' }}>
                    <i className={reward.icon} style={{ fontSize: "50px", color: "white" }}></i></div>
                      <div style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <div >
                      <p>
                         {reward.description}
                         </p>
                      </div>
                    </div>
                  </Card> ))}
                  </Grid>
            
                <Grid item className='ContactUs-rows1-2'>
                {rewardsData3.map((reward, index) => (
                  <Card key={index} className="card" style={{ color: "white", padding: "20px", textAlign: "center" }} >
                    <div className="pad" style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white' }}>
                    <i className={reward.icon} style={{ fontSize: "50px", color: "white" }}></i></div>
                      <div style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <div >
                      <p>
                         {reward.description}
                         </p>
                      </div>
                    </div>
                  </Card> ))}
                  </Grid>

            <Grid item >
                  
            <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/Gateflix.jpg" style={{width:"20vh" , height: "8vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 50 CA’s get 75% off on GATE courses provided GATEFLIX.</li>
                      <li>Top 200 CA’s will get 50% off on GATE courses provided GATEFLIX.</li>
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

                  <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/VSkills.jpg" style={{width:"20vh" , height: "10vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 10 CA’s will be offered internships after they qualify.</li>
                      <li>Top 50 CA’s will receive a 35% discount on courses offered by VSkills.</li>
                      <li>Top 200 CA’s will receive a 25% discount on courses offered by VSkills.</li>
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

                  <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/TestFunda.png" style={{width:"20vh" , height: "10vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 35 CA’s will receive free courses each worth RS.5000/- from TestFunda.
                      </li>
                      <li>Top 200 CA’s will receive a 50% discount on courses offered by TestFunda.</li>
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

                  <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/ILMS.jpg" style={{width:"15vh" , height: "10vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 150 CA’s will receive courses worth Rs.1,50,000/- from ILMS Academy.  </li>
                      
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

                  <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/ElearnMarkets.jpeg" style={{width:"15vh" , height: "15vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 15 CA’s will receive internships as decided through the interview process.
                      </li>
                      <li>Top 30 CA’s will receive 30 python certification courses.</li>
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

                  <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/IALM.jpg" style={{width:"10vh" , height: "10vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 10 CA’s will receive courses worth Rs.80,000/-.
                      </li>
                      <li>Top CA’s will receive 25+ discount coupons worth Rs.2500/- each.</li>
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

                  <Card className="card" style={{ width: "auto", color: "white", padding: "20px" }} >
                    <Grid container className="ContactUs" style={{ justifyContent: "center", background: 'transparent', color: 'white' }}>
                     <Grid item sx={{display: "flex", alignItems: "center"}} ><img src="/assets/newcompanies/Buzzz.png" style={{width:"10vh" , height: "10vh",color: "white" }}/></Grid >
                      <Grid style={{ display: "flex", justifyContent: "center", background: 'transparent', color: 'white', fontSize: '1.2rem' }}>
                      <ul>                     
                      <li>Top 5 CA’s will receive internships to be decided through the interview process.
                      </li>
                      <li>Top 15 CA’s will receive Forest Ambassador Program.
                      </li>
                      <li>Top 25 CA’s will receive India MUN Youth Leadership Courses.
                      </li>
                      </ul>
                      
                      </Grid>
                  </Grid>
                  </Card>

              </Grid>
                
                
        
      </Grid>
      {/* <div className="background" style={{ backgroundImage: 'url(assets/img/whyca3.webp)' }}></div> */}
    

            
        </Card> <Grid xs></Grid>
          
        </div>
    
  )
}

