import React from 'react'
import {Link} from 'react-router-dom'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import './styles.css'
export default function Admin() {

  return (
    <div>
    <h1 className='Adminhead'>Admin Page</h1><br/><div className='adminCenter'>
    <Stack spacing={2} direction="row">
      
        <Link style={{textDecoration:'none'}} to="/admin=E3B25DB2A2749648E4F34D19A57DE/create-task"><Button variant='contained'>Create a new Task</Button></Link>
        <Link style={{textDecoration:'none'}} to="/admin=E3B25DB2A2749648E4F34D19A57DE/task-all"><Button variant='contained'>List of All Tasks</Button></Link>
        <Link style={{textDecoration:'none'}} to="/admin=E3B25DB2A2749648E4F34D19A57DE/all-request"><Button variant='contained'>List of All Upload Requests</Button></Link>
        <Link style={{textDecoration:'none'}} to="/admin=E3B25DB2A2749648E4F34D19A57DE/all-users"><Button variant='contained'>List of All Users</Button></Link>
</Stack>
    </div></div>
  )
}
