import axios from 'axios'
import React, { useRef } from 'react'
import {Card,Button} from 'react-bootstrap'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import {useFetch} from './userInfo'
import Alluser from './AllUsers'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
// import { UseAutocompleteProps } from '@mui/material';
export default function RequestAdminNode(props) { 

const pointsRef = useRef();
const remarksref = useRef();
function handleAccept(e){
  
    window.location.reload()
    const bonus=parseInt(pointsRef.current.value)
    const remarks = remarksref.current.value;
    axios.post('https://caportal25.onrender.com/api/admin-accept',
    {email:props.element.email,
    increasedPoints: bonus,
    points:String(bonus) + "/" + String(props.element.taskpoints),
    taskid:props.element.taskid,
    requestId:props.element._id,
    remarks: remarks
    }).then((res)=>
    console.log(res)
    ).then((err)=>console.log(err))
}

function handleDecline(e){
  console.log(props.element.email)
    window.location.reload()
    axios.post('https://caportal25.onrender.com/api/admin-decline',
    {email:props.element.email,
    points:props.element.taskpoints,
    taskid:props.element.taskid,
    requestId:props.element._id
    }).then((res)=>
    console.log(res)
    ).then((err)=>console.log(err))
  
}
  return (
    <>
    
         <div className='request'>
         <TableContainer>
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
            <TableRow>
            {/* <TableCell sx={{color:'#fff',width:'50px'}}>{props.element.techid}</TableCell> */}
            <TableCell sx={{color:'#fff' ,width:'250px'}}>{props.element.email}</TableCell>
            {/* <TableCell sx={{color:'#fff' ,width:'100px'}}>{props.element.name}</TableCell> */}
            {/* <TableCell sx={{color:'#fff',width:'100px'}}><Typography align='left'>{props.element.phone}</Typography></TableCell> */}
           <TableCell sx={{color:'#fff',width:'200px'}}><Typography align='center'>{props.element.taskname}</Typography></TableCell>
           <TableCell sx={{color:'#fff',width:'50px'}}><Typography align='left'>{props.element.taskpoints}</Typography></TableCell>
           <TableCell sx={{color:'#fff',width:'150px'}}><input ref={pointsRef} type='number' defaultValue='0'></input></TableCell>
           <TableCell sx={{color:'#fff',width:'150px'}}><input ref={remarksref} type='text' defaultValue=''></input></TableCell>
           <TableCell sx={{color:'#fff',width:'50px'}}><Typography align='center'><a href={props.element.imageurl} target="_blank"><RemoveRedEyeIcon/></a></Typography></TableCell>
           <TableCell sx={{color:'#fff',width:'50px'}}><Button type="button" onClick={handleAccept}><CheckIcon/></Button></TableCell>
           <TableCell sx={{color:'#fff',width:'50px'}}><Button type="button" onClick={handleDecline}><ClearIcon/></Button></TableCell>
          </TableRow>
          </Table>
          </TableContainer>
    </div>
                </>
  )
}