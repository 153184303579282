// src/components/LandingPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Stack,Card, Typography, Dialog,Grid, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import Sidenavbar from './Userlayout/Sidenavbar';
import { FaGripfire, FaInstagram, FaBlogger, FaUser } from "react-icons/fa";
import "./LandingPage.css";
import Bgvideolanding from './Userlayout/bgvideolanding';
import {useMediaQuery, useTheme} from '@mui/material';



const LandingPage = () => { 

  const testimonials = [
    {
      img: "./assets/bala.jpg",
      text: "My experience with the Techkriti'22 Campus Ambassador programme has been fantastic and memorable. The tasks as a campus ambassador assisted me in improving my communication and management skills. I am grateful to the team Techkriti for providing me with a platform to hone my skills.",
      name: "- Balakumaran"
    },
    {
      img: "./assets/bhuvesh.jpg",
      text: "I worked as campus ambassador for techkriti19. I got to learn a lot from the program and built a good social circle. It was a wonderful experience and I definitely enjoyed my duration working there as a campus ambassador. I was so grateful that I got the opportunity.",
      name: "- Bhuvesh Mandloi"
    },
    {
      img: "./assets/user.png",
      text: "The Techkriti'22 Campus Ambassador Program has been a blessing to me, and I'm grateful for the opportunity. I learned a lot about event promotion and how to provide attendees with the most up-to-date information possible.",
      name: "- Krish Shivhare"
    },
    {
      img: "./assets/user.png",
      text: "Techkriti's Campus Ambassador Program was an incredible opportunity and privilege for me.The entire leaves no stone unturned in providing opportunities for personal growth.Huge regard, affection, and devotion for the entire Techkriti team.",
      name: "- Rakhi Singh "
    },              
    {
      img: "./assets/user.png",
      text : "For the first time, I served as a Campus Ambassador.I was exposed to many new things.I was able to gain marketing experience while also becoming a member of such an illustrious institution thanks to this opportunity.For the most part, it was a wonderful experience.",
      name: "-  Shivam Kumar"
    },
    {
      img: "./assets/harshita.jpeg",
      text: "Serving as a Techkriti's CA, I championed IITK's premier technical festival and spearheaded the TechnoCruise workshop. Techkriti amplified my leadership skills while providing a national platform, making this collaboration a pivotal milestone in my academic and professional journey.",
      name: "- Harishita Rana"
    },
    {
      img: "./assets/anshika.jpeg",
      text: "I became a part of Techkriti to gain a profound understanding of technology beyond mere learning. Our mentors played a pivotal role, guiding us through various challenges. We embraced these challenges head-on, accumulating invaluable insights and experiences in the tech world.",
      name: "- Anshika Arya"
    },
    // Add more testimonials as needed
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };
  
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };
  
  const { img, text, name } = testimonials[currentIndex];
  
  const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  
      return (
  
    <div className='landing'>
      <Bgvideolanding/>
    
        <Grid container spacing={2}  >
    
      <Grid item xs={12} sx={{boxShadow:0, border:0}}>
          
        </Grid>
        <Grid item sm = {2} sx={{boxShadow:0, border:0}}>
          <div><Sidenavbar  /></div>
        </Grid>
         <Grid item xs={12} sm = {8}>

        <Card style={{  background: 'transparent', color:'#fff', borderRadius:'20px'}}>  
        
        <Grid container className="content" justifyContent="center" spacing={2}>

        {/* Home     */}
        
        <Grid item className="content" >
        
          {isLargeScreen && (<div style={{display:"flex", justifyContent: "center"}} >
              <div > 
              <img src="assets/img/CA_light.png" alt="CA Logo" style={{ height: '150px', width: '600px' }}/>
              </div>
          </div>
          )}

        {!isLargeScreen && (<div style={{display:"flex", justifyContent: "center"}}>
              <div > 
              <img src="assets/img/CA_light.png" alt="CA Logo" style={{ height: '100px', width: '300px' }}/>
              </div>
          </div>
          )}
            
            
            {isLargeScreen && (
            <div className="desc" style={{display:"flex", justifyContent: "center"}}>
             <p  >
            <span className='desc_p'>Represent your college in</span><br/>
            Asia's Largest Technical and Entrepreneurial Festival
            </p>
            </div>
            )}
            {!isLargeScreen && (
            <div className="desc" style={{display:"flex", justifyContent: "center"}}>
             <p  >
            <span >Represent your college in</span><br/>
            Asia's Largest Technical <br/>and Entrepreneurial Festival
            </p>
            </div>
            )}
            
            <br/>

            
            <div className="landing-sponsor">
                 <div>
                <h1 style={{color: "white"}}>Title Sponsor</h1>
                <img src="/assets/img/iandt.jpeg" alt="I & T Logo" style={{ height: '200px', width: '200px',marginLeft: '5px' }} />
                </div>
                {/* <div style={{ marginLeft: '40px' }} >
                <h1 style={{color: "white"}}>Co Powered by</h1>
                <img src="/assets/newcompanies/unstop.png" alt="Unstop Logo" style={{ height: '75px', width: '180px' }} />
                 </div> */}
                 <div >
                <h1 style={{color: "white"}}>Brought to you by</h1>
                <Box style={{background: "white"}}><img src="/assets/newcompanies/Buzzz.png" alt="BoE Logo" style={{ height: '125px', width: '250px' }} /></Box>
                 </div>
             </div>
          

           

            <br />
            <br />
            <br />
            <br />
        </Grid>
       
        <hr style={{ width: "100%" }} />
        
        

    {/* About Techkriti */}
    <Grid item className="slide fade-6 kenBurns fromLeft" id="about">
      <div className="content">
                  
                <h1  style={{ color: "#20B2AA" ,display: 'inline', fontSize:"3em" }}>
                  About  Techkriti
                </h1>
                                
                
                <div style={{display:"flex", justifyContent: "center"}}>
                <p >
                  <span className="opacity-8">
                    Techkriti is the annual inter-collegiate Technical and Entrepreneurship festival organized by the
                    students of IIT Kanpur. Techkriti is a non-profit organization which was launched in 1995 with the
                    aim of developing interest and encouraging innovation in technology among students of India. Indian
                    Institute of Technology Kanpur, widely celebrated as a centre of academic excellence, is proud to
                    announce the 31st edition of its Technological and Entrepreneurial Festival, Techkriti. Over time,
                    Techkriti has firmly established its reputation as one of the largest technical festivals in Asia,
                    igniting the passion within budding individuals and providing a platform for them to showcase their
                    skills. Innovation and perseverance form the forefront of this blockbuster event, with social welfare
                    also getting the importance it deserves. Every year we impute ambassadors from different colleges
                    throughout the country, whose main responsibility is to publicize Techkriti, in and out of his/her
                    college, while giving them the opportunity to develop their organizational and management skills.
                  </span>
                </p>
                </div>
      </div>
      <br />
            <br />
           
      {/* <div className="background" style={{ backgroundImage: `url(${backgroundImage})` }}></div> */}
    </Grid>
    <hr style={{ width: "100%" }}/>

    {/* Why CA */}
    <Grid item className="slide fade-6 kenBurns fromRight" id="ca">
      <div className="content">
       
              <div className="fix-12-12 left toLeft">
                  <h1 className="ae-1" style={{ display: 'inline', color: '#20B2AA', fontSize:"3em" }}>
                   Why CA?
                  </h1>
                  </div>
                <div style={{display:"flex", justifyContent: "center"}}>
                <p >
                  <ul className="opacity-8">
                    <li>Golden opportunity to witness the grandeur of Techkriti in one of the most renowned and prestigious institutions of the country, IIT Kanpur.</li>
                    <li>Excellent platform to sharpen your Communication and Technical skills.</li>
                    <li>Be a part of national level event promotion and brand management.</li>
                    <li>Chance to organize National Level Competitions in your college.</li>
                    <li>Form a valuable network with thousands of brilliant like-minded students from all across the country.</li>
                    <li>An easy way to get acquainted with ever-changing trends of the Technical and Entrepreneurial World.</li>
                    <li>Opportunity to keep abreast with cutting-edge technologies from IIT Kanpur.</li>
                  </ul>
              
                </p>
                </div>
      </div>

    <br />
            
        
    </Grid>
    <hr style={{ width: "100%" }}/>

    {/* Responsibilities */}
    <Grid item className="slide fade-6 kenBurns" id="respo">
      <div className="content" >
                  <div style={{display:"flex", justifyContent: "center"}} >
                  <h1 style={{ display: 'inline', fontSize:"3em", color: '#20B2AA' }}>Responsibilities</h1><br/><br/>
                  </div> 
                  <div>
                   
                   <div className='ContactUs-rows'>
                   <Card className="card">
                      <div className="imgBx">
                      <FaUser style={{ fontSize: '80px' }} />
                      </div>
                      <div className="contentBx">
                        <h2>Coordination</h2>
                        <p>
                          CAs arrange workshops and activities in their respective colleges with the aid of Techkriti. Thus, they serve as the liaison between Techkriti and the respective college administrations.
                        </p>
                      </div>
                    </Card>
                   

                    
                    <Card className="card">
                      <div className="imgBx">
                        <FaGripfire style={{ fontSize: '80px' }} />
                      </div>
                      <div className="contentBx">
                        <h2>College Publicity</h2>
                        <p>
                          A campus ambassador also helps in publicizing Techkriti and ensuring participation of the respective campus community.
                        </p>
                      </div>
                    </Card>
                    
                    </div>
                      
                      <div className='ContactUs-rows' >
                      <Card className="card">
                      <div className="imgBx">
                        <FaInstagram style={{ fontSize: '80px' }} />
                      </div>
                      <div className="contentBx">
                        <h2>On Social Media</h2>
                        <p>
                          CAs must also promote Techkriti via social media platforms by utilizing their network for the development of Techkriti '24.
                        </p>
                      </div>
                    </Card>
                      
                    

                    
                    <Card className="card">
                      <div className="imgBx">
                        <FaBlogger style={{ fontSize: '80px' }} />
                      </div>
                      <div className="contentBx">
                        <h2>Through Blogs</h2>
                        <p>
                          A campus ambassador also enhances his/her writing abilities through our blog series, where they can share opinions on any topic for publication on Techkriti's official social media handles.
                        </p>
                      </div>
                    </Card>
                    
                   </div>  
                </div>                
            </div>
              
    <br />
            <br />
            <br/>
               
    </Grid>
    <hr style={{ width: "100%" }}/>

    {/* Testimonials */}
    <Grid item className="testimonials-section">
      <div style={{display:"flex", justifyContent: "center"}} >
      <h1 className="ae-1" style={{ textAlign: 'center', color: '#20B2AA', fontSize: '3rem' }}>Testimonials</h1><br/>
      </div>
      
      <div style={{display:"flex", justifyContent: "center"}} >
      <div className="testimonial-card"  >
        <div className="img-card">
          <img src={img} alt="Testimonial" />
        </div>
        <div className="testimonial-text">
          <p>{text}</p>
          <p className="testimonial-name">{name}</p>
        </div>
      </div>
      </div>

      <br/>
      <div style={{display:"flex", justifyContent: "center", marginTop: "1rem"}}>
        <button onClick={handlePrevious} >Previous</button>
        <button onClick={handleNext}>Next</button>
      </div>
    
      <br/>
      <br/>
      <br/>
      </Grid>  
    <hr style={{ width: "100%" }}/>

    {/* Sponsors */}
    <Grid item className="slide fade-6 kenBurns" id="sponsors">
      <div className="content">
          <div style={{display:"flex", justifyContent: "center"}} >
          <h1 style={{ textAlign: 'center', color: '#20B2AA', fontSize: '3rem' }}>Sponsors</h1><br/> 
          </div> 
          </div>

            
            
              <div style = {{display:"flex", justifyContent: "center"}}>
              <h1 style={{ fontSize: "3rem"}}>Title Sponsor </h1>
                </div>  
            
           
            <div style={{display:"flex", justifyContent:"center"}}> 
              <div alignItems={"center"} display={"flex"} justifyContent={"center"}>
                <img src="/assets/img/iandt.jpeg" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                </div>
                </div>



           {isLargeScreen && (
            <>
              <div style={{display:"flex", justifyContent:"center", gap: "150px"}}> 

            <div style = {{display:"flex", justifyContent: "center"}}>
              <h2 style={{ fontSize: "2rem"}}>Brought to you by </h2>
                </div>  

                <div style = {{display:"flex", justifyContent: "center"}}>
              <h2 style={{ fontSize: "2rem"}}>Co Powered By </h2>
                </div> 

            </div>
           
            <div style={{display:"flex", justifyContent:"center", gap: "180px"}}> 
              <div alignItems={"center"} display={"flex"} justifyContent={"center"}>
                <Box className = "spons">
                <img src="/assets/newcompanies/Buzzz.png" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                </Box>
                </div>
            
              <div alignItems={"center"} display={"flex"} justifyContent={"center"}>
              <Box className = "spons">
                <img src="/assets/newcompanies/unstop.png" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                </Box>
                </div>
                
  
            </div></> )}

            {!isLargeScreen && (
            <>
               

            <div style = {{display:"flex", justifyContent: "center"}}>
              <h2 style={{ fontSize: "2rem"}}>Brought to you by </h2>
                </div>  

                <div style={{display:"flex", justifyContent:"center", gap: "180px"}}> 
              <div alignItems={"center"} display={"flex"} justifyContent={"center"}>
                <Box className = "spons">
                <img src="/assets/newcompanies/Buzzz.png" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                </Box>
                </div>  
           </div>
          
                <div style = {{display:"flex", justifyContent: "center"}}>
              <h2 style={{ fontSize: "2rem"}}>Co Powered By </h2>
                </div> 

                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}> 
              <div display={"flex"} justifyContent={"center"} alignItems={"center"}  >
              <Box className = "unstop" >
                <img src="/assets/newcompanies/unstop.png" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                </Box>
                </div>
                </div>
                
  
            </> )}



           


            <div style={{display:"flex", justifyContent: "center"}}>
                  
                  <h3 style={{fontSize: "2em"}}>Learning Partner</h3>
                 
                
            </div>

            <div className='list-3' > 
            <div className="grid-item">
              <div style = {{display:"flex", justifyContent: "center"}}>
              <br/><br/><br/><br/><br/>
              <Box className = "spons">
                <img src="/assets/newcompanies/VSkills.jpg" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                </Box>
              </div>
            </div> 
            

            <div className="grid-item">
              <div style = {{display:"flex", justifyContent: "center"}}>
              
              <br/><br/><br/>
              <Box className = "spons" alignItems={"center"} display={"flex"}>
                <img src="/assets/newcompanies/Gateflix.jpg" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
              </Box>
               
              </div>
            </div>


            <div className="grid-item">
              <div style = {{display:"flex", justifyContent: "center"}}>
           
              <br/><br/><br/><br/><br/><br/><br/>
                <img src="/assets/newcompanies/TestFunda.png" alt="Seekho Logo" style={{width:"40vh"}} /><br/><br/><br/>
                
              </div>
            </div>

            </div>




            <div style={{display:"flex", justifyContent: "center"}}>
                  
                  <h3 style={{fontSize: "2em"}}>Education Partner</h3>
                 
                
            </div>

            <div className='list-4' > 
            <div className="grid-item">
              <div style = {{display:"flex", justifyContent: "center"}}>
              <br/><br/><br/><br/><br/>
              
                <img src="/assets/newcompanies/ILMS.jpg" alt="Seekho Logo" style={{width:"30vh"}} /><br/><br/><br/>
                
              </div>
            </div> 
            

            <div className="grid-item">
              <div style = {{display:"flex", justifyContent: "center"}}>
              
              <br/><br/><br/>
              
                <img src="/assets/newcompanies/IALM.jpg" alt="Seekho Logo" style={{width:"20vh"}} /><br/><br/><br/>
             
              </div>
            </div>


            </div>

                  <br/>  <br/> <br/> <br/>
          
        
     </Grid>
    


        </Grid>
        </Card>
       </Grid>
    </Grid>
    
</div>
  
  );
};

export default LandingPage;
