import React from "react"
import TaskList from './TaskList'
import { Box } from "@mui/material"


export default function Dashboard() {
  return (
    <>
    <Box sx={{alignItems: "center", justifyItems: "center"}} >
    <TaskList />
    </Box>
      
    </>
  )
}
