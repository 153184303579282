import axios from 'axios'
import React,{useState,useEffect}from 'react'
import {useAuth} from '../context/AuthContext'
import useFetch from './userInfo'
import {Card} from 'react-bootstrap'
import TaskCreateNodePending from './TaskCreateNodePending'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box';
import TaskCreateNode from './TaskCreateNode'

// function taskEntry(task){
//     return(
//         <TaskCreateNodePending
//             key = {task._id}
//             id = {task._id}
//             title ={task.title}
//             points = {task.points}
//             content = {task.content}
//             offeredDate = {task.OfferedDate}
//             lastDate = {task.lastDate}
//             isPending = {task.isApproved}
            
//         />
//     )
// }

export default function TaskPending() {
    const [dataArray, setDataArray] = useState([]);
    const {currentUser} = useAuth()
    // const userInfo = useFetch(currentUser.uid)
    useEffect(()=>{
        axios.get(`https://caportal25.onrender.com/api/fetch-completed-tasks?email=${encodeURIComponent(currentUser.email)}`, {}).then((response)=>{
            setDataArray(response.data)
            // console.log(dataArray[0].title)
        }).catch((e)=>console.log(e))
    }, []) 
    console.log(dataArray)
  return (
    <Box style={{  background: 'transparent', color:'#fff', borderRadius:'20px', marginTop: '80px'}}>
    <Box >
    <Typography variant="h2" align='center' color={"white"} fontFamily={"fantasy"} >Task Attempted</Typography> 
    </Box>
        <Grid container>
        {
            dataArray.map((element)=>{
                return <TaskCreateNodePending element={element}/>
            })
        }
        
    </Grid>
    
    </Box>
  )
}
