import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {Container} from 'react-bootstrap'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Grid, Typography } from '@mui/material';
import RequestAdminNode from './RequestAdminNode';
import Alluser from './AllUsersNode';

// let dataArray =[]

// let alluser = [];

// function requestEntry(request){
  
//   let obj = alluser.find(o => o.email === request.email)
//   let name = obj.name;
//   let phone = obj.phone;
//   let techid = obj.techid, email = obj.email;
//   // if(obj === null){
//   //   name= '';
//   //   phone='';
//   //   techid = '';
//   //   email = '';
//   // } else {
//   //   name = obj.name;
//   //   phone = obj.phone;
//   //   techid = obj.techid;
//   //   email = obj.email;
//   // }
//   dataArray['name'] = name
//   dataArray['phone'] = phone
//   dataArray['techid'] = techid
//   dataArray['email'] = name
//   return <RequestAdminNode
//       key = {request._id} 
//       email = {request.email}
//       taskid ={request.taskid}
//       imageurl={request.imageurl}
//       // imageid = {request.fileid}
//       // filePath = {request.filePath}
//       taskname = {request.taskname}  
//       taskpoints = {request.taskpoints}
//       requestId = {request._id}
//       name = {name}
//       phone = {phone}
//       techid = {techid}
//       length = {dataArray.length}
//     />   
//       }
//       dataArray.map((userSingle)=>{
//         let obj = alluser.find(o => o.email === userSingle.email)
//         let name;
//   let phone;
//   let techid, email;
//   if(obj === null){
//     name= '';
//     phone='';
//     techid = '';
//     email = '';
//   } else {
//     name = obj.name;
//     phone = obj.phone;
//     techid = obj.techid;
//     email = obj.email;
//   }
//   userSingle['name'] = name
//   userSingle['phone'] = phone
//   userSingle['techid'] = techid
//   userSingle['email'] = email
// })

export default function RequestAdmin(props) {
  const [dataArray, setDataArray] = useState([]);
  
  useEffect(() => {
    axios.get('https://caportal25.onrender.com/api/get-all-requests').then((response)=>{
        setDataArray(response.data)
    }).catch((err)=>console.log(err))
    
  }, [])
  console.log(dataArray)
  return (
    <>
    <h1>All Requests</h1>
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
    <TableHead>
        <TableRow>
        <Grid container spacing={1}>
        {/* <Grid item><TableCell sx={{color:'#fff', width:'50px'}}>TechId</TableCell></Grid> */}
        <Grid item><TableCell sx={{color:'#fff', width:'250px'}}>Email</TableCell></Grid>
        {/* <Grid item><TableCell sx={{color:'#fff', width:'100px'}}>Name</TableCell></Grid> */}
        {/* <Grid item><TableCell sx={{color:'#fff', width:'100px'}}>Phone</TableCell></Grid> */}
        <Grid item><TableCell sx={{color:'#fff', width:'200px'}}>Task Name</TableCell></Grid>
        <Grid item><TableCell sx={{color:'#fff', width:'50px'}}>Max points</TableCell></Grid>
        <Grid item><TableCell sx={{color:'#fff', width:'150px'}}>Points</TableCell></Grid>
        <Grid item><TableCell sx={{color:'#fff', width:'150px'}}>Remarks</TableCell></Grid>
        <Grid item><TableCell sx={{color:'#fff', width:'50px'}}>Image</TableCell></Grid>
        <Grid item><TableCell sx={{color:'#fff', width:'50px'}}>Accept/decline</TableCell></Grid>
          {/* <TableCell sx={{color:'#fff'}}>Task Name</TableCell>
          <TableCell sx={{color:'#fff'}}>Task Point</TableCell>
          <TableCell sx={{color:'#fff'}}>Image</TableCell>
          <TableCell sx={{color:'#fff'}}>Bonus(if applicable)</TableCell>
          <TableCell sx={{color:'#fff'}}>(Accept/Decline)</TableCell> */}
          </Grid>
          </TableRow>
        </TableHead>
        </Table>
        </TableContainer>
        <Container>
             {dataArray.map((element)=>{
                return <RequestAdminNode element={element}></RequestAdminNode>
            })}
        </Container>

    
        </>
  )
}