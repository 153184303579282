import React from 'react'
import axios from 'axios'
import { Container} from 'react-bootstrap'
import TaskNodeAdmin from './TaskNodeAdmin'
let allTask;
axios.get('https://caportal25.onrender.com/api/get-all-tasks').then((response)=>{
    allTask = response.data
    // console.log(response.data)
}).catch((e)=>console.log(e))

function taskEntry(task){
    return (
        <>
        <TaskNodeAdmin 
            key = {task._id}
            title={task.title}
            id = {task._id}
            content = {task.content}
            link = {task.link}
            points = {task.points}
            lastDate = {task.lastDate}
            offeredDate = {task.OfferedDate}  
            />
        <br />
        <br />
        </>
    )
}
export default function ShowTask() {
  return (
    <Container >
        { 
            allTask.map(taskEntry)
        } 
    </Container>
  )
}
