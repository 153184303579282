import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Alert } from 'react-bootstrap'
import { useAuth } from '../context/AuthContext';
import Button from '@mui/material/Button';
import './styles.css';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Grid, Typography } from '@mui/material';
import { func } from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';


export default function UpdateProfile() {

  const { currentUser } = useAuth();
  const [user, setUser] = useState();
  
  const nameRef = useRef();
  const collegeRef = useRef();
  const collegeCityRef = useRef();
  const collegeStateRef = useRef();
  const phoneRef = useRef();
  const referalid = useRef();
  const linkedinRef = useRef();
  const facebookRef = useRef();
  const instagramRef = useRef();
  const programme = useRef();
  const yearofjoin = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  
  useEffect(() => {
    async function func() {
      const User = await axios.post('https://caportal25.onrender.com/api/getuserdata', {email: currentUser.email});
      setUser(User.data);
      console.log(User);
      nameRef.current.value = User.data.name;
      collegeRef.current.value = User.data.college;
      collegeCityRef.current.value = User.data.collegeCity;
      collegeStateRef.current.value = User.data.collegeState;
      phoneRef.current.value = User.data.phone;
      referalid.current.value = User.data.referralId;
      linkedinRef.current.value = User.data.linkedin;
      facebookRef.current.value = User.data.facebook;
      instagramRef.current.value = User.data.instagram;
      programme.current.value = User.data.program;
      yearofjoin.current.value = User.data.joiningYear;
    }

    func();
  }, []);

  function isAllDigits(str) {
    return /^[0-9]+$/.test(str);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSuccess('');
    setLoading(true);
    setError('');
  
    try {
      if(!isAllDigits(phoneRef.current.value) || phoneRef.current.value.length < 10) {
        window.alert("Please enter a valid number");
        return;
      }

      const response = await axios.post("https://caportal25.onrender.com/api/update-profile", {
        name: nameRef.current.value,
        email: currentUser.email,
        // techid: techidRef.current.value,
        phone: phoneRef.current.value,
        college: collegeRef.current.value,
        collegeCity: collegeCityRef.current.value,
        collegeState: collegeStateRef.current.value,
        linkedin: linkedinRef.current.value,
        facebook: facebookRef.current.value,
        instagram: instagramRef.current.value,
        referalid: referalid.current.value,
        program: programme.current.value,
        year: yearofjoin.current.value
      });
  
      setLoading(false);
      console.log(response.data);
  
      if (response.data.success) {
        setSuccess('Profile Updated. Go to Dashboard.');
      } else {
        setError('Error updating profile');
      }
    } catch (err) {
      setLoading(false);
      setError('Error updating Profile');
      console.error(err);
    }
  }
  
  return (
    <div className='profile-card' >
      <div className='profile update' style={{  background: 'radial-gradient(circle at 10% 20%, rgb(87, 108, 117) 0%, rgb(22, 30, 33) 100.2%)', color:'#fff', borderRadius:'20px', marginTop: '100px'}}>
        <Grid container >
          <Grid item xs>
            <Typography vvariant='h2' align='center' className='h2profile' fontFamily={"fantasy"} fontSize={"50px"}>Update Profile
            </Typography></Grid>
            <br/><br/>
          <Grid>
            
            <div><Form onSubmit={handleSubmit}>

              <Form.Label>Name</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" inputRef={nameRef} placeholder = {user?.name} />


              <Form.Label>College</Form.Label>
              <TextField style={{color:"white"}} sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" placeholder = {user?.college} inputRef={collegeRef} required />

              <Form.Label>College City</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" placeholder = {user?.collegeCity} inputRef={collegeCityRef} required />
              
              <Form.Label>College State</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" placeholder = {user?.collegeState} inputRef={collegeStateRef} required />

              <Form.Label>Programme 
              <Tooltip title="B.Tech, B.B.A., etc">
                    <InfoIcon sx={{ fontSize: 16, marginLeft: '4px', cursor: 'pointer' }} />
                </Tooltip>
              </Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" placeholder = {user?.program} inputRef={programme} required />
              
              {/* <Form.Label>Year of Joining</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" inputRef={yearofjoin} required /> */}
              <Form.Label>Year of Joining</Form.Label>
              <br/><br/>
              <Select style={{"border":"1px solid #ced4da", "color": "white"}}
                sx={{
                  "&:hover": {
                    border: "solid #ced4da",
                  }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
                }} margin='large' size='small' fullWidth type="name" label="Year of Joining" inputRef={yearofjoin} required
              >
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
              <br />
              <br />
              <Form.Label>Phone 
              <Tooltip title="Write 10 digits only.. without +91">
                    <InfoIcon sx={{ fontSize: 16, marginLeft: '4px', cursor: 'pointer' }} />
                </Tooltip>
              </Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} inputProps={{ maxLength: 10 }} type="tek" margin='normal' placeholder = {user?.phone} size='small' fullWidth inputRef={phoneRef}  required />

              <Form.Label>Referral ID

              <Tooltip title= "Enter the C.A. id of your friend to gain 5 extra Techkriti coins">
                    <InfoIcon sx={{ fontSize: 16, marginLeft: '4px', cursor: 'pointer' }} />
                </Tooltip>
              </Form.Label>
              
              <TextField className='textfield'
               sx={{
                  "&:hover": {
                    border: "solid #ced4da",
                  },
                  input: {
                    color: "white",
                    borderColor: "white",
                    border: "1px solid #ced4da",
                  },
                }}
                margin="normal"
                size="small"
                fullWidth
                type="text"
                inputRef={referalid}
                placeholder={user?.referralID}
                
                disabled={!user || user?.referralId !== ''} 
              />

              <Form.Label>Linkedin URL</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" inputRef={linkedinRef} placeholder = {user?.linkedin}/>
              <Form.Label>Facebook URL</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" inputRef={facebookRef} placeholder = {user?.facebook}/>
              <Form.Label>Instagram URL</Form.Label>
              <TextField sx={{
                "&:hover": {
                  border: "solid #ced4da",
                }, input: { color: 'white', borderColor: 'white', border: '1px solid #ced4da', }
              }} margin='normal' size='small' fullWidth type="name" inputRef={instagramRef} placeholder = {user?.instagram} />
              <Form.Label>Email</Form.Label><br />
              <Form.Control
                className="textfield"
                fullWidth
                disabled
                margin='normal'
                size='small'
                defaultValue={currentUser.email}
                required
              /><br /><br />
              <Button variant= "contained" disabled={loading} type="submit" className="w-100 mt-3">Update</Button>
            </Form><br /></div>
          </Grid>
          {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
        </Grid>
      </div>
      <div className="backto">
        <Button variant='contained' ><Link style={{ textDecoration: 'none', color: '#fff' }} to="/dashboard">Back to Dashboard</Link></Button>
      </div>
      <div>
      </div>
    </div>
  )
}
