import React, { useState } from 'react'
import Dashboard from "./Dashboard"
import Login from "./Login"
import {Container} from "react-bootstrap"
import { AuthProvider } from "../context/AuthContext";
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import UpdateProfile from './UpdateProfile'
import Admin from './Admin'
import Profile from './Profile'
import TaskAdminCreate from './TaskAdminCreate';
import PrivateRouteAdmin from './PrivateRouteAdmin'
import ShowTask from './ShowTaskAdmin'
import RequestAdmin from './RequestAdmin'
import TaskList from './TaskList'
import Userlayout from './Userlayout/Userlayout';
import Adminlayout from './Adminlayout/Adminlayout';
import TaskPending from './TaskPending';
import Leaderboard from './Leaderboard';
import AuthError from './AuthError'
import AllUsers from './AllUsers'
import LandingPage from './LandingPage';
import  Incentives  from './Incentives';
import  ContactUs  from './ContactUs';

// import Signup from './Signup';

function App(){ 
  return (
    
      <Container className="d-flex align-items-center justify-content-center"style={{minHeight : "100vh"}}>
        <div className="w-100" style={{maxWidth : '100%'}}>
          <Router>
            <AuthProvider>
            <Routes>
            <Route exact path="/" element={<LandingPage />}/>
              <Route exact path="dashboard" element={<PrivateRoute><Userlayout /></PrivateRoute>}>
                <Route exact index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path="profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
                <Route exact path="task-all" element={<PrivateRoute><TaskList/></PrivateRoute>}/>
                <Route exact path="update-profile" element={<PrivateRoute><UpdateProfile/></PrivateRoute>}/>
                <Route exact path="task-pending" element={<PrivateRoute><TaskPending/></PrivateRoute>}/>
                <Route exact path="leaderboard" element={<PrivateRoute><Leaderboard/></PrivateRoute>}/>
                <Route exact path="incentives" element={<PrivateRoute><Incentives/></PrivateRoute>}/>
                <Route exact path="contact-us" element={<PrivateRoute><ContactUs/></PrivateRoute>}/>
              </Route>
              <Route exact path="/autherror" element={<AuthError/>} ></Route>
              
              {/* <Route exact path="/signup" element={<Signup/>}/> */}
              <Route exact path="/admin=E3B25DB2A2749648E4F34D19A57DE" element={<PrivateRouteAdmin><Adminlayout /></PrivateRouteAdmin>}>
                {/* <Route exact index element={<Admin />} /> */}
                <Route exact path="create-task" element={<PrivateRouteAdmin><TaskAdminCreate/></PrivateRouteAdmin>} />
                <Route exact path="task-all" element={<PrivateRouteAdmin><ShowTask/></PrivateRouteAdmin>} />
                <Route exact path="all-request" element={<PrivateRouteAdmin><RequestAdmin/></PrivateRouteAdmin>} />
                <Route exact path="all-users" element={<PrivateRouteAdmin><AllUsers /></PrivateRouteAdmin>} />
                {/* <Route path="" element={<TaskList/>}/> */}

              </Route>
            </Routes>
              {/* <Routes>
                <Route exact path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/> 
                <Route path="/update-profile" element={<PrivateRoute><UpdateProfile/></PrivateRoute>}></Route>
                <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}></Route>
                <Route path="/Grid" element={<PrivateRoute><Grid/></PrivateRoute>}></Route>
                <Route path="/admin" element={<PrivateRouteAdmin><Admin/></PrivateRouteAdmin>}></Route>
                <Route path="/show-tasks" element={<PrivateRouteAdmin><ShowTask/></PrivateRouteAdmin>}></Route>
                <Route path="/create-task" element={<PrivateRouteAdmin><TaskAdminCreate/></PrivateRouteAdmin>}></Route>
                <Route path="/all-request" element={<PrivateRouteAdmin><RequestAdmin/></PrivateRouteAdmin>}></Route>
                <Route path="/login" element={<Login/>}></Route>
                <Route path="/task-all" element={<TaskList/>}></Route>
                <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
              </Routes> */}
            </AuthProvider>
          </Router>
        </div>
      </Container>
    

  );
}

export default App;
