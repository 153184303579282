import React from 'react';
import '../styles.css';
import bgvideo from './vecteezy_futuristic-tunnel-3-d-illustration-with-neon-lamps-on-walls_2181465.mov';

export default function Bgvideo() {
    return (
        <div>
        <video autoPlay loop muted id ="video" preload="auto">
        <source src={bgvideo} type="video/mp4"/>
        </video>

        </div>
    )
}

