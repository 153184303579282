import React from 'react'
import {Card} from 'react-bootstrap'
import { styled } from '@mui/material/styles';
import { CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './styles.css';
import {useMediaQuery, useTheme} from '@mui/material';

const useStyles = makeStyles({
  flexGrow: {
    flex: '1',
  },
  button: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#3c52b2 !important',
  },
}});
export default function TaskCreateNodePending(props) {
    const classes = useStyles();
    const m = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const deadline = new Date(props.element.lastDate);
  const deadlineDate = deadline.getDate() + ' ' + m[deadline.getMonth()] + ' ' + deadline.getFullYear() + ', ' + deadline.getHours() + ":" + deadline.getMinutes() + ' AM';
const url = props.element.link;
const linkgiven = props.element.link !== '';

const theme = useTheme();
const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
        <div style={{borderColor:'#000'}} >
        <Grid container>
    <Grid item xs={2}>
    <Box sx={{ minWidth: 300,boxSizing: 'border-box'}}>
    
            <CardContent>
            
            {isLargeScreen && (<div className='task'>
                <div className='pendingTask'>
                    
                       <h1>{props.element.title}</h1> 
                  
                    <div className='pointspending'>
                        Techkriti Coins Received &nbsp;
                        <Button className={classes.button} variant='text' sx={{backgroundColor:'rgb(32,178,170)', height:'30px'}} rounded>
                            {props.element.points}
                        </Button>
                   </div>
                </div><br/><br/> <br />
                <p className='dataTask' style={{ whiteSpace: 'pre-line' }}> {props.element.content}</p>
                  {linkgiven &&  <p>Link :<a href={url}>{props.element.link}</a></p> } 
                <div>Deadline: {deadlineDate}</div>     
                <div>Remarks: {props.element.remarks}</div> 
                <div className='status'>Status</div> <Button className={classes.button}  variant='text' sx={{backgroundColor:'rgb(32,178,170)', height:'30px'}}> {props.element.points === '---' ? "Pending" : "Success"}</Button>
                </div>)}

                {!isLargeScreen && (<div className='task'>
                <div className='pendingTask'>
                    
                       <h1>{props.element.title}</h1> 
                      
                  
                    <div >
                      <br/><br/><br/><br/>
                        Techkriti Coins Received &nbsp;
                        <Button className={classes.button} variant='text' sx={{backgroundColor:'rgb(32,178,170)', height:'30px'}} rounded>
                            {props.element.points}
                        </Button>
                   </div>
                </div><br/>
                <p className='dataTask' style={{ whiteSpace: 'pre-line' }}> {props.element.content}</p>
                  {linkgiven &&  <p>Link :<a href={url}>{props.element.link}</a></p> } 
                <div>Deadline: {deadlineDate}</div>     
                <div>Remarks: {props.element.remarks}</div> 
                <div className='status'>Status</div> <Button className={classes.button}  variant='text' sx={{backgroundColor:'rgb(32,178,170)', height:'30px'}}> {props.element.points === '---' ? "Pending" : "Success"}</Button>
                </div>)}

               
            </CardContent>
            
            
        </Box>
        </Grid>
        </Grid>



            {/* <Card.Body>
                <strong>Task ID</strong>:{props.element.id}<br/>
                <strong>Task Name</strong>: {props.title}<br/>
                <strong>Task Content</strong>: {props.content}<br/>
                <strong>Offered Date</strong>: {props.offeredDate}<br/>
                <strong>Last Date</strong>: {props.lastDate}<br/>
                <strong>Task Points</strong>: {props.points}<br/>
                <strong>Status</strong>: {props.isPending ? "Success" : "Pending"}
            </Card.Body> */}
        </div> 
    )
}
