import React from 'react'
import FileUpload from './FileUpload'
import { CardContent,Card } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import './styles.css';
import {useMediaQuery, useTheme} from '@mui/material';

const useStyles = makeStyles({
  flexGrow: {
    flex: '1',
  },
  button: {
    color: '#fff !important',
    borderRadius:'50% !important',
    height:'60px !important',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      borderRadius:'70% !important'
    },
    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#3c52b2 !important',
  },
}});

// function convertUrlsToLinks(text) {
//   const urlRegex = /(https?:\/\/[^\s]+)/g;
//   return text.replace(urlRegex, url => `<a href="${url}" target="_blank"> ${url} </a>`);
// }
function convertUrlsToLinks(text) {
  // const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  // return text.replace(urlRegex, `<a href="$1" target="_blank"> $1 </a>`);
  return text.replace(urlRegex,"<a href='$1' target='_blank'> $1 </a>");
}

function TextWithLinks(props) {
  return <p dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(props.text) }} />;
}

export default function TaskCreateNode(props) {
  const classes = useStyles();
  // console.log(props.content)
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(2),
        margin: theme.spacing(3),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      }));
     
  const m = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const release = new Date(props.offeredDate);
  const releaseDate = release.getDate() + ' ' + m[release.getMonth()] + ' ' + release.getFullYear();
  
  const deadline = new Date(props.lastDate);
  const deadlineDate = deadline.getDate() + ' ' + m[deadline.getMonth()] + ' ' + deadline.getFullYear() + ', ' + deadline.getHours() + ":" + deadline.getMinutes() + ' AM';
  const url = props.link;
  const linkgiven = props.link !== '';

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div style={{borderColor:'#000'}} >
  
    <Grid item xs={12}>
    <Box sx={{ width : "auto",boxSizing: 'border-box'}} >
    
            <CardContent>
            
            {isLargeScreen && (<div className='task'>
                <div className='dataTask'>
                    <h1>
                        {props.title}
                    </h1>
                    <div className='points'>
                        Maximum Techkriti Coins &nbsp;
                        <Button className={classes.button} variant='text' sx={{backgroundColor:'rgb(128,128,128)', height:'30px'}} rounded>
                            {props.points}
                        </Button>
                   </div> 
                   
                </div><br/><br/>
                {/* <pre className='dataTask' > {props.content} </pre> */}
                <p className='dataTask' style={{ whiteSpace: 'pre-line' }}>{props.content}</p>
                  {linkgiven &&  <p> {"   "}Link :<a href={url}>{props.link}</a></p> } 
                

                <div className='dataTask'>Release Date: {releaseDate}</div>
                <div className='dataTask'>Deadline: {deadlineDate}</div>
                
                <FileUpload taskid={props.id} taskname={props.title} taskpoints ={props.points} />
                
                </div> )}

                {!isLargeScreen && (<div className='task'>
                <div className='dataTask'>
                    <h1>
                        {props.title}
                    </h1>
                    <br/><br/><br/>
                    <div >
                        Maximum Techkriti Coins &nbsp;
                        <Button className={classes.button} variant='text' sx={{backgroundColor:'rgb(128,128,128)', height:'30px'}} rounded>
                            {props.points}
                        </Button>
                   </div> 
                   
                </div>
                {/* <pre className='dataTask' > {props.content} </pre> */}
                <p className='dataTask' style={{ whiteSpace: 'pre-line' }}>{props.content}</p>
                  {linkgiven &&  <p> {"   "}Link :<a href={url}>{props.link}</a></p> } 
                

                <div className='dataTask'>Release Date: {releaseDate}</div>
                <div className='dataTask'>Deadline: {deadlineDate}</div>
                
                <FileUpload taskid={props.id} taskname={props.title} taskpoints ={props.points} />
                
                </div> )}
                
            </CardContent>
            
            
        </Box>
        </Grid>
      
        </div>
    )
}