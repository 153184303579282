import React,{useState,useEffect}from 'react'
import {Alert} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import { Box,Card, CardContent, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import "./styles2.css";
import { FaEnvelope, FaIcons } from 'react-icons/fa';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function ContactUs() {
    
  return (
   
    <div>
        <Card style={{  background: 'transparent', color:'#fff', borderRadius:'20px', marginTop: '70px'}}>
            <Box sx={{paddingTop: "30px"}}>
            <Typography variant='h2' align='center' fontFamily={"fantasy"}>Contact Us</Typography>
            </Box>
            <br/><br/>
            

            <Grid container spacing = {2} justifyContent="center" >
              <Grid item className='ContactUs-rows1-2'  >
                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/Avanti Wasekar.jpg"
                          alt="Avanti Wasekar"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover"     // Ensures the image scales properly inside the circle
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Avanti Wasekar</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:avantisng2203@gmail.com">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/avanti-wasekar-77a9572b3">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-8999894768</p>
                      </div>
                    </Card>

                    
                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/Khushboo Rai.jpg"
                          alt="Khushboo Rai"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover"     // Ensures the image scales properly inside the circle
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Khushboo Rai</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:khushboorai16108@gmail.com">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/khushboo-kumari-b51445253/">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-8058865477</p>
                      </div>
                    </Card>

                    </Grid>

                    <Grid item className='ContactUs-rows1-2'    >

                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/Aaryan Jain.jpg"
                          alt="Aaryan Jain"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover"     // Ensures the image scales properly inside the circle
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Aaryan Jain</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:aaryanjain712@gmail.com">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/aaryan-jain-72487a255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-7804875641</p>
                      </div>
                    </Card>


                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/Jyotsna Arya.jpg"
                          alt="Jyotsna Arya"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover"     // Ensures the image scales properly inside the circle
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                        
                        <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Jyotsna Arya</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:jyotsnaarya12@gmail.com ">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jyotsna-arya-33b028258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-7303575495</p>
                      </div>
                    </Card>
                    
                    </Grid>
      
                    <Grid item className='ContactUs-rows'  >
                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/Akhil Vishnu Naik.jpg"
                          alt="Akhil Vishnu Naik"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover" ,    // Ensures the image scales properly inside the circle
                  
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Akhil Vishnu Naik</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:akhil_vn@techkriti.org">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/akhilvnaik">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-9730934366</p>
                      </div>
                    </Card>

                    
                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/IMG20240525233517 - Hospitality Techkriti.jpg"
                          alt="Nirmal Prajapati"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover"     // Ensures the image scales properly inside the circle
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Nirmal Prajapati</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:nirmalp@techkriti.org">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/nirmal-prajapati-6b0492226?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-7623063076</p>
                      </div>
                    </Card>

                    <Card className="card" style={{ color: "white", padding: "20px", textAlign: "center" }}>
                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }} >
                        
                        <img
                          src="/assets/Contact/Amandeep Upadhyay.jpeg"
                          alt="Amandeep Upadhyay"
                          style={{
                            width: "150px",   // Adjust size as needed
                            height: "150px",
                            borderRadius: "50%",   // This makes the image circular
                            objectFit: "cover"     // Ensures the image scales properly inside the circle
                          }}
                        />
                      </div>
                      <div className="contactdetails">
                       
                      <span style={{ fontWeight: "bold", fontSize: "1.5rem", letterSpacing: "0.1rem" }}>Amandeep Upadhyay</span> 
                          <br/>
                          <span style={{ fontWeight: "bold", fontSize: "1rem", letterSpacing: "0.1rem" }}>Organizer, Hospitality</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: "20px",
                            gap: "20px"
                          }}
                        >
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:amandeepu@techkriti.org">
                              <FaEnvelope color='white' style={{ fontSize: "25px" }} />
                            </a>
                          </div>
                          <div style={{ margin: "auto 10px" }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://in.linkedin.com/in/amandeep-upadhyay-a839b9245">
                              <LinkedInIcon style={{ fontSize: "30px", color: "white"}} />
                            </a>
                          </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginTop: "20px", fontWeight: "bold" }}>+91-8318785132</p>
                      </div>
                    </Card>

                    </Grid>
             
            </Grid>
   
            
        </Card> 
          
        </div>
    
  )
}

